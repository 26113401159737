/* HotPicks.css */

.hot-picks-container {
  width: 100%;
  background-color: #1d1d1d; /* Dark background matching your site */
  border-radius: 8px;
  margin: 20px auto;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  transition: transform 0.4s ease, opacity 0.4s ease;
}

/* 
  Banner area containing the scroll container and close button.
  Display flex so the button and scroll area can be side by side. 
*/
.hot-picks-banner {
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

/* 
  The horizontal scrolling container. 
  We want no visible scrollbars so it looks like a marquee.
  But we do want users to be able to scroll on mobile if they touch.
*/
.hot-picks-scroll {
  position: relative;
  flex: 1;
  display: flex;
  white-space: nowrap;     /* Items will not wrap to the next line. */
  overflow-x: scroll;      /* Let users scroll on mobile. */
  scrollbar-width: none;   /* For Firefox hide scrollbar */
  -ms-overflow-style: none; /* For IE/Edge hide scrollbar */
  margin-right: 10px;      /* optional margin to avoid close button overlap */
  scroll-behavior: smooth; 
  gap: 10px;               /* spacing between items */
  padding-bottom: 5px;
}

/* Hide scrollbar in WebKit-based browsers */
.hot-picks-scroll::-webkit-scrollbar {
  display: none;
}

/* Style for each bet item */
.hot-pick-item {
  flex: 0 0 auto;        /* Do not shrink or grow, keep natural width */
  background-color: #2c2c2c;
  border-radius: 6px;
  padding: 10px;
  min-width: 150px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
  /* Optional: force single-line overflow to show ellipsis (if needed) */
  /* white-space: nowrap; overflow: hidden; text-overflow: ellipsis; */
}

.hot-pick-item:hover {
  transform: scale(1.05);
}

/* 
  Special style for no bets 
  (if you want a different look for "No Bets Available")
*/
.hot-pick-item.no-bets {
  font-style: italic;
  color: #ddd;
}

/* Close button styling: appear at the far right */
.close-btn {
  order: 2;
  background: none;
  border: none;
  color: #01D449;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease;
  flex-shrink: 0;
  margin-left: 10px;
}

.close-btn:hover {
  color: #1ca733;
}

/* Container for the expanded tile below the scrolling strip */
.expanded-tile-container {
  margin: 20px;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .hot-pick-item {
    min-width: 120px;
    padding: 8px;
  }
  .close-btn {
    font-size: 20px;
  }
}
